import { cn } from '@eyecarehealth/ds-aquilae-react';
import { motion, spring, useAnimate } from 'framer-motion';
import { useEffect, useState } from 'react';

type AnimatedSelectorProps = {
  options: {
    label: string;
    description?: string;
    value: string;
    image: string;
  }[];
  imageClassName?: string;
  onSelectorChange: React.Dispatch<React.SetStateAction<string>>;
  optionSelected: string;
};

export function AnimatedSelector({ onSelectorChange, options, optionSelected, imageClassName }: AnimatedSelectorProps) {
  const [canHover, setCanHover] = useState(false);
  const [scope, animate] = useAnimate();

  async function handleAnimate() {
    try {
      await animate('img', { opacity: 0, y: 24 }, { duration: 0 });
      options.forEach((option, index) => {
        animate(`#${option.value}Image`, { opacity: 1, y: 4, scale: 1 }, { duration: 0.5, type: spring, stiffness: 150, delay: index * 0.15 });
      });
    } catch (error) {
      console.error(error);
    }
  }

  function onMouseEnter(id: string) {
    try {
      const imageId = `#${id}Image`;
      animate(imageId, { opacity: 1, y: 4, scale: 1.15 }, { duration: 0.3, type: spring, stiffness: 100 });
    } catch (error) {
      console.error(error);
    }
  }

  function onMouseLeave(id: string) {
    try {
      const imageId = `#${id}Image`;
      animate(imageId, { opacity: 1, y: 4, scale: 1 }, { duration: 0.3, type: spring, stiffness: 100 });
    } catch (error) {
      console.error(error);
    }
  }

  async function onSelectOption(value: string) {
    try {
      onSelectorChange(value);
      const animateId = `#${value}Image`;
      const unselectedImageIds = options.filter((option) => option.value !== value).map((option) => `#${option.value}Image`);
      await Promise.all(
        unselectedImageIds.map(async (id) => await animate(id, { opacity: 1, y: 4, scale: 1 }, { duration: 0.3, type: spring, stiffness: 100 })),
      );
      animate(animateId, { opacity: 1, y: 4, scale: 1.15 }, { duration: 0.3, type: spring, stiffness: 100 });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      handleAnimate();
    }, 300);
  }, []);

  setTimeout(() => {
    setCanHover(true);
  }, 2000);

  return (
    <div ref={scope} className="flex flex-col gap-3">
      {options.map((option) => {
        const isSelected = option.value === optionSelected;
        return (
          <button
            key={option.value}
            onMouseEnter={() => !isSelected && onMouseEnter(option.value)}
            onMouseLeave={() => !isSelected && onMouseLeave(option.value)}
            onClick={() => onSelectOption(option.value)}
            className={cn(
              'rounded-2 flex w-[420px] max-w-full items-center gap-3 border-2 border-gray-200 p-3 text-left transition-all duration-300',
              isSelected && 'border-brand-primary-500',
              !canHover && 'pointer-events-none',
            )}>
            <div
              className={cn(
                'flex h-5 w-5 items-center justify-center rounded-full border border-gray-200 transition-all duration-300',
                isSelected && 'bg-brand-primary-500',
              )}>
              <span
                className={cn('h-3 w-3 scale-0 rounded-full bg-white opacity-0 transition-all duration-300', isSelected && 'scale-100 opacity-100')}
              />
            </div>
            <div className="flex flex-1 flex-col gap-1">
              <h3 className="text-typography text-base font-semibold">{option.label}</h3>
              {option.description && <p className="text-typography-secondary text-sm">{option.description}</p>}
            </div>
            <motion.div
              id={`${option.value}Wrapper`}
              className={cn(
                'bg-brand-primary-50 flex items-center justify-center overflow-hidden rounded-full transition-all duration-300',
                isSelected && 'bg-brand-primary-200',
              )}>
              <motion.img
                initial={{ opacity: 0 }}
                id={`${option.value}Image`}
                className={cn('size-[88px] object-contain', imageClassName)}
                src={option.image}
                alt="Register Type"
              />
            </motion.div>
          </button>
        );
      })}
    </div>
  );
}
