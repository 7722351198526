import { cn } from '@/lib/utils';
import { useEffect, useRef } from 'react';

type ImagePreviewProps = React.AllHTMLAttributes<HTMLDivElement> & {
  files?: FileList | File | null; // Aceitando FileList ou File diretamente
  imageUrl?: string;
  placeholder?: string;
};

export function ImagePreview({ files, imageUrl, className, placeholder }: ImagePreviewProps) {
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (files) {
      let file: File | null = null;

      // Determina se `files` é FileList ou File
      if (files instanceof FileList && files.length > 0) {
        file = files[0];
      } else if (files instanceof File) {
        file = files;
      }

      if (file && imageRef.current) {
        const objectUrl = URL.createObjectURL(file);
        imageRef.current.src = objectUrl;

        // Cleanup: revoga o URL para liberar memória
        return () => URL.revokeObjectURL(objectUrl);
      }
    } else if (imageUrl && imageRef.current) {
      imageRef.current.src = imageUrl;
    }
  }, [files]);

  return (
    <div className={className}>
      {!files && !imageUrl && placeholder && <strong className="text-typography-secondary font-semibold opacity-60">{placeholder}</strong>}
      <img className={cn('max-h-full max-w-full', !files && !imageUrl && 'hidden')} ref={imageRef} alt="Preview" />
    </div>
  );
}
