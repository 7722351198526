import BVUtils from '@bv/utils';
import { UserFromToken } from '../../../../../types/v2/user';

type ProtectedByPlanProps = {
  children: React.ReactNode;
  requiredModules: string[];
};

export function ProtectedByPlan({ children, requiredModules }: ProtectedByPlanProps) {
  const decodedToken: UserFromToken = BVUtils.token.getDecodedToken();

  if (
    !decodedToken ||
    !decodedToken.modules ||
    decodedToken.modules.length === 0 ||
    !requiredModules.some((permission) => decodedToken.modules.includes(permission))
  ) {
    return null;
  }

  return children;
}
