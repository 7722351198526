import { cn } from '@/lib/utils';
import { Input, Label, Loader } from '@eyecarehealth/ds-aquilae-react';
import { MapPinIcon } from 'lucide-react';
import { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

type AddressAutocompleteProps = React.AllHTMLAttributes<HTMLDivElement> & {
  handleSelectAddress: (suggestion: any) => void;
};

export function AddressAutocomplete({ className, handleSelectAddress }: AddressAutocompleteProps) {
  const [inputSearchAddressValue, setInputSearchAddressValue] = useState('');

  function setInputSearchAddressValueHandler(value: string) {
    setInputSearchAddressValue(value);
    handleSelectAddress(undefined);
  }

  return (
    <div className={cn('', className)}>
      <PlacesAutocomplete
        value={inputSearchAddressValue}
        onChange={setInputSearchAddressValueHandler}
        // onSelect={handleSelectAddress}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div className="relative z-10 w-full space-y-1">
              <Label htmlFor="addreess">Selecionar endereço</Label>
              <Input
                {...getInputProps({
                  id: 'address',
                  placeholder: 'Digite a rua ou o CEP',
                  className: 'location-search-input w-full rounded-l-0',
                })}
                value={inputSearchAddressValue}
              />

              <div
                className={cn(
                  'absolute left-[2px] top-[64px] max-h-[250px] w-[calc(100%-4px)] overflow-y-auto rounded-b-lg bg-white shadow-lg',
                  suggestions?.length > 0 && 'border border-gray-400',
                )}>
                {loading && (
                  <div className="flex justify-center py-3">
                    <Loader className="size-4" />
                  </div>
                )}
                <div className="flex flex-col">
                  {suggestions?.map((suggestion) => (
                    <button
                      {...getSuggestionItemProps(suggestion, {
                        onClick: () => {
                          handleSelectAddress(suggestion);
                          setInputSearchAddressValue(suggestion.description);
                        },
                        className: cn(
                          'flex w-full items-start justify-start gap-2 p-2 transition-all hover:bg-gray-200',
                          suggestion.active && 'bg-gray-200',
                        ),
                      })}
                      key={suggestion.placeId}>
                      <MapPinIcon className="text-brand-primary" size={20} />
                      <div className="flex flex-1 flex-col gap-1 text-left">
                        <strong className="text-typography-secondary text-sm">{suggestion.formattedSuggestion.mainText}</strong>
                        <span className="text-typography-secondary text-xs">{suggestion.formattedSuggestion.secondaryText}</span>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          );
        }}
      </PlacesAutocomplete>
      <div className="rounded-t-[40px] bg-[rgba(0,0,0,0.8)]" />
    </div>
  );
}
