import { Frown, RefreshCcw, Terminal } from 'lucide-react';
import { Button } from './Button';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/accordion';
import { homemTristeAsset } from '../assets';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';

type TErrorPleaseReloadProps = {
  err: Error;
  info: React.ErrorInfo;
  props: any;
};

export function ErrorPleaseReload({ err, info, props }: TErrorPleaseReloadProps) {
  const [animate, setAnimate] = useState(false);
  const [collapseValue, setCollapseValue] = useState<string>('');

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div className="z-11 fixed flex h-screen w-screen flex-col items-center justify-center bg-white">
      <div
        className={cn(
          'flex max-w-[800px] flex-1 translate-y-2 items-center gap-8 opacity-0 transition-all duration-500',
          animate && 'opacity-1 translate-y-0',
        )}>
        <img src={homemTristeAsset} className="h-[300px] w-[320px]" />
        <div className="flex flex-col items-start gap-2">
          <h1 className="text-3xl font-bold text-gray-800">Problema inesperado!</h1>
          <p className="text-lg text-gray-600">Pedimos desculpas pelo transtorno. Que tal recarregar a página e tentar novamente?</p>
          <Button variant="outline" onClick={() => window.location.reload()}>
            <RefreshCcw size={18} className="mr-2" />
            Recarregar
          </Button>
        </div>
      </div>
      <Accordion
        onValueChange={(value) => setCollapseValue(value)}
        type="single"
        collapsible
        className={cn('w-[800px] opacity-0 transition-all duration-500', animate && 'opacity-1')}>
        <AccordionItem value="item-1">
          <AccordionTrigger className="text-danger">
            <div className="flex items-center gap-3">
              <span className="relative flex">
                <span
                  className={cn(
                    'bg-danger/80 duration-1500 absolute inline-flex h-4 w-4 rounded-full opacity-[0]',
                    !collapseValue && 'animate-ping opacity-[0.75]',
                  )}
                />
                <Terminal size={16} className="flex-1" />
              </span>
              <span className="text-semibold text-xs">Detalhar erro da página</span>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <Alert variant="destructive">
              <AlertDescription className="flex items-start gap-2">
                <div className="text-xs">
                  {err.message}
                  {info.componentStack}
                  {/* {info.digest} */}
                </div>
              </AlertDescription>
            </Alert>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
