import React, { useState } from 'react';
import {
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  Pagination as SCNPagination,
} from './ui/pagination';
import { cn } from '@/lib/utils';
import { Table } from '@tanstack/react-table';

type TPaginationProps = {
  table: Table<any>;
};

export const usePagination = (): {
  pagination: { pageIndex: number; pageSize: number };
  setPagination: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
} => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  return { pagination, setPagination };
};

export function Pagination({ table }: TPaginationProps) {
  const previousPageDisabled = !table.getCanPreviousPage();
  const nextPageDisabled = !table.getCanNextPage();

  return (
    <SCNPagination>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            size="default"
            className={cn('rounded-1 text-typography-primary cursor-pointer no-underline', previousPageDisabled && 'pointer-events-none opacity-50')}
            onClick={() => table.previousPage()}
          />
        </PaginationItem>
        <PaginationItem>
          <span className={cn('mx-4 text-base font-semibold', previousPageDisabled && nextPageDisabled && 'opacity-50')}>
            {table.getState().pagination.pageIndex + 1} de {table.getPageCount().toLocaleString()}
          </span>
        </PaginationItem>
        <PaginationItem>
          <PaginationNext
            size="default"
            className={cn('rounded-1 text-typography-primary cursor-pointer no-underline', nextPageDisabled && 'pointer-events-none opacity-50')}
            onClick={() => {
              table.nextPage();
            }}
          />
        </PaginationItem>
      </PaginationContent>
    </SCNPagination>
  );
}
