import { 
    Popover as PopoverUI, 
    PopoverContent, 
    PopoverTrigger 
} from './ui/popover';

type IPopover = {
    trigger: React.ReactNode;
    children: React.ReactNode;
}
const Popover = ({ trigger, children, ...props }: IPopover) => {
    return (
        <PopoverUI {...props}>
            <PopoverTrigger>{trigger}</PopoverTrigger>
            <PopoverContent>{children}</PopoverContent>
        </PopoverUI>
    );
}

export default Popover;