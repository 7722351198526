import { cn } from '@eyecarehealth/ds-aquilae-react';

type FeedbackProps = {
  title?: string;
  description?: string;
  textHighlight?: string;
  icon?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
  variant?: 'warning' | 'error' | 'success' | 'info' | 'neutral' | 'transparent';
};

export function Feedback({ title, description, textHighlight, icon, actions, className, variant = 'neutral' }: FeedbackProps) {
  const variantToBackgroundClassName = {
    warning: 'bg-warning',
    error: 'bg-destructive',
    success: 'bg-success',
    info: 'bg-brand-primary-50',
    neutral: 'bg-gray-100',
    transparent: 'bg-transparent',
  };

  return (
    <div className={cn(`rounded-2 mx-auto max-w-[600px] p-4`, variantToBackgroundClassName[variant], className)}>
      <div className="flex flex-col items-center justify-center gap-2 text-center">
        {icon}
        {title && <h1 className="text-typography-secondary text-lg font-bold">{title}</h1>}
        {description && <p className="text-typography-secondary text-sm opacity-80">{description}</p>}
        {textHighlight && <strong className="text-typography-secondary text-sm font-bold">{textHighlight}</strong>}
        {actions && <div className="mt-4 flex gap-2">{actions}</div>}
      </div>
    </div>
  );
}
