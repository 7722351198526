import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { eyeAnimationAsset, logoBVAsset, logoEyeCareWhiteAsset } from '../../assets';

type TLoaderProps = {
  className?: string;
  text?: string;
};

export function Loader({ className, text }: TLoaderProps) {
  return (
    <div className="flex items-center gap-2">
      <svg className={cn('h-5 w-5 animate-spin text-gray-500', className)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      {text && <span className={cn('font-semibold text-gray-500', className)}>{text}</span>}
    </div>
  );
}

export function PageLoader() {
  const [animate, setAnimate] = useState(false);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: eyeAnimationAsset,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div className={'fixed left-0 top-0 z-10 flex h-screen w-screen flex-col items-center justify-center'}>
      <div className={cn('flex scale-75 items-center gap-2 opacity-0 transition-all', animate && 'opacity-1 scale-100')}>
        <Lottie options={lottieOptions} height={60} width={60} />
        <img src={logoBVAsset} alt="Logo" className="w-24" />
      </div>
      <div className="fixed bottom-8 left-0 mt-4 flex w-full items-center justify-center gap-2">
        <Loader className="h-6 w-6" />
        <span className="font-semibold text-gray-500">Carregando...</span>
      </div>
    </div>
  );
}

export function PageLoaderEyecare() {
  return (
    <div className="fixed left-0 top-0 z-20 flex h-screen w-screen items-center justify-center bg-[#101828]">
      <img className="logo animate-breath" src={logoEyeCareWhiteAsset} alt="Eyecare" />
    </div>
  );
}
