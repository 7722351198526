import { ColumnDef, flexRender, Table as TTable } from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './ui/table';
import { Loader } from './Loader';

type TDataTableProps = React.RefAttributes<HTMLTableElement> & {
  table: TTable<any>;
  columns: ColumnDef<any>[];
  loading?: boolean;
  loadingText?: string;
};

export function DataTable({ table, columns, loading, loadingText, ...props }: TDataTableProps) {
  if (loading) {
    return (
      <Table {...props}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <TableRow className="pointer-events-none">
            <TableCell colSpan={columns.length} className="h-24 text-center">
              <div className="my-6 flex justify-center">
                <Loader text={loadingText || 'Buscando dados...'} />
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  return (
    <Table {...props}>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              <strong className="text-typography-primary">Nenhum resultado encontrado.</strong>
              <span className="text-typography-secondary">Considere alterar os filtros de sua busca, caso aplicável.</span>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
