import { DetailedHTMLProps, ReactNode, HTMLAttributes, useState, useId, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { ptBR } from 'date-fns/locale';
import { CalendarIcon, Check, ChevronsUpDown, Plus, X } from 'lucide-react';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { NumberFormatBase, NumericFormat, PatternFormat } from 'react-number-format';
import { CurrencyInput } from 'react-currency-mask';
import { toast } from 'sonner';
import { Badge } from '../ui/badge';
import { Calendar } from '../ui/calendar';
import { Form as FormBase, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, useFormField } from '../ui/form';
import { Input as InputBase } from '../ui/input';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../ui/input-otp';
import { Label } from '../ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../ui/select';
import { Switch } from '../ui/switch';
import { Textarea } from '../ui/textarea';
import { Checkbox } from '../ui/checkbox';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { format, isValid, parse } from 'date-fns';
import { Skeleton } from '../ui/skeleton';
import { IconButton } from '../Button';
import { Button } from '../ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import AsyncSelect from 'react-select/async';
import Highlighter from 'react-highlight-words';
import { TOptions } from '../../../../old/types/select';

export enum EInput {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  NUMBER = 'number',
  TEL = 'tel',
  CNPJ = 'cnpj',
  MASK = 'mask',
  FILE = 'file',
  SWITCH = 'switch',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  SELECT_SEARCH = 'select_search',
  SELECT_SEARCH_ASYNC = 'select_search_async',
  DATEPICKER = 'datepicker',
  DATERANGE = 'daterange',
  COLORPICKER = 'colorpicker',
  MULTIPLE_TEXT = 'multiple_text',
  OTP = 'otp',
  CHECKBOX = 'checkbox',
  CHECKBOX_GROUP = 'checkbox_group',
  RADIO_GROUP = 'radio_group',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
}

export type TFormProps = {
  children: ReactNode;
  ctx: any;
  onSubmit?: (data: any) => void;
  className?: string;
};

export type TInputProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'defaultValue'> & {
  type: EInput;
  name: string;
  label: string | ReactNode;
  placeholder?: string;
  description?: string | ReactNode;
  mask?: string;
  patternChar?: string;
  disabled?: boolean;
  defaultValue?: any;
  defaultChecked?: boolean;
  selectGroup?: string;
  options?: Array<{ value: any; label: string }>;
  loadOptions?: (searchText: string) => Promise<any>;
  multipleFiles?: boolean;
  accepts?: string;
  inputMultipleTextRules?: RegExp;
  numberOfMonths?: number;
  radioItems?: { value: string; text: string }[];
  checkboxItems?: { value: string; label: string }[];
  loading?: boolean;
  disabledDate?: any;
  maxLength?: number;
  minLength?: number;
  maxValue?: number;
  minValue?: number;
  decimalScale?: number;
};

type TInputHandlerProps = TInputProps & {
  field: any;
};

type TInputMaskProps = Omit<TInputProps, 'name' | 'type' | 'label'> & {
  field: any;
};

type TInputSwitchProps = Omit<TInputProps, 'type' | 'label' | 'name'> & {
  field: any;
};

type TInputCheckboxProps = Omit<TInputProps, 'type' | 'label' | 'name'> & {
  field: any;
};

type TInputCheckboxGroupProps = Omit<TInputProps, 'type' | 'label' | 'name'> & {
  field: any;
};

type TInputRadioGroupProps = Omit<TInputProps, 'type' | 'label' | 'name'> & {
  field: any;
};

type TInputSelectProps = Omit<TInputProps, 'type' | 'label' | 'name'> & {
  field: any;
};

type TInputSelectSearchProps = Omit<TInputProps, 'type' | 'label' | 'name'> & {
  field: any;
};

type TInputSelectSearchAsyncProps = Omit<TInputProps, 'type' | 'label' | 'name'> & {
  field: any;
};

type TInputDatePickerProps = Omit<TInputProps, 'name' | 'type' | 'label'> & {
  field: any;
};

type TInputDateRangeProps = Omit<TInputProps, 'name' | 'type' | 'label'> & {
  field: any;
};

type TInputMultipleTextProps = Omit<TInputProps, 'name' | 'type' | 'label'> & {
  field: any;
};

type TInputFileProps = Omit<TInputProps, 'type' | 'label'> & {
  field: any;
};

type TInputCurrencyProps = Omit<TInputProps, 'type' | 'label' | 'name'> & {
  field: any;
};

type TInputPercentageProps = Omit<TInputProps, 'type' | 'label' | 'name'> & {
  field: any;
  decimalScale: number;
};

function InputMask({ field, mask, placeholder, disabled }: TInputMaskProps) {
  const { error } = useFormField();

  return (
    <PatternFormat
      disabled={disabled}
      className={cn(error && 'border-danger border-2')}
      value={field.value}
      onChange={(e) => field.onChange(e.target.value)}
      placeholder={placeholder}
      format={mask!}
      customInput={InputBase}
    />
  );
}

function InputSwitch({ field, disabled, defaultChecked }: TInputSwitchProps) {
  const { value } = field;

  return (
    <div className="flex items-center space-x-2">
      <Switch
        className="data-[state=checked]:bg-primary"
        defaultChecked={defaultChecked}
        disabled={disabled}
        id={field.name}
        checked={value}
        onCheckedChange={field.onChange}
      />
      <Label className="font-semibold" htmlFor={field.name}>
        {value ? 'Sim' : 'Não'}
      </Label>
    </div>
  );
}

function InputCheckbox({ field, disabled }: TInputCheckboxProps) {
  const { value } = field;

  return <Checkbox disabled={disabled} checked={field.value} onCheckedChange={field.onChange} />;
}

function InputCheckboxGroup({ field, disabled, checkboxItems }: TInputCheckboxGroupProps) {
  const { value } = field;

  return (
    <div className="flex gap-4">
      {checkboxItems?.map((item, index) => {
        return (
          <FormItem key={item.value} className="flex flex-row items-start space-x-3 space-y-0">
            <FormControl>
              <Checkbox
                checked={field.value?.includes(item.value)}
                onCheckedChange={(checked) => {
                  return checked
                    ? field.onChange([...field.value, item.value])
                    : field.onChange(field.value?.filter((value) => value !== item.value));
                }}
              />
            </FormControl>
            <FormLabel className="font-normal">{item.label}</FormLabel>
          </FormItem>
        );
      })}
    </div>
  );
}

function InputRadioGroup({ field, disabled, radioItems }: TInputRadioGroupProps) {
  const { value } = field;

  return (
    <RadioGroup onValueChange={field.onChange} defaultValue={field.value} className="flex flex-wrap gap-4">
      {radioItems?.map((item, index) => (
        <FormItem key={`${item.value}-${index}`} className="flex items-center space-x-2 space-y-0">
          <FormControl>
            <RadioGroupItem value={item.value} />
          </FormControl>
          <FormLabel className="font-normal">{item.text}</FormLabel>
        </FormItem>
      ))}
    </RadioGroup>
  );
}

function InputSelect({ field, disabled, selectGroup, placeholder, options, loading }: TInputSelectProps) {
  // TODO: Implementar logica de carregar select com dados de API
  const { error } = useFormField();

  return (
    <Select onValueChange={field.onChange} value={field.value} disabled={disabled || loading}>
      <SelectTrigger className={cn('relative w-full whitespace-nowrap', error && 'border-destructive border-2')}>
        <SelectValue placeholder={placeholder || 'Selecione uma opção'} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {options && options?.length > 0 ? (
            <>
              <SelectLabel>{selectGroup || 'Opções'}</SelectLabel>
              {options?.map((option, index) => (
                <SelectItem key={`${field.name}-${option.value}-${index}`} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </>
          ) : (
            <SelectLabel>{selectGroup || 'Não há opções para selecionar'}</SelectLabel>
          )}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function InputSelectSearch({ field, options, placeholder = 'Selecione' }: TInputSelectSearchProps) {
  const { setValue } = useFormContext();
  const { error } = useFormField();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant="outline"
            role="combobox"
            className={cn(
              'w-full justify-between border-gray-400 px-3 font-normal text-black',
              !field.value && 'text-muted-foreground',
              error && 'border-destructive border-2',
            )}>
            {field.value ? options.find((options) => options.value === field.value)?.label : placeholder}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" asChild>
        <Command>
          <CommandInput placeholder="Buscar..." />
          <CommandList>
            <CommandEmpty>Nenhuma opção encontrada.</CommandEmpty>
            <CommandGroup>
              {options.map((option, index) => (
                <CommandItem
                  value={option.label}
                  key={option.value + index}
                  onSelect={() => {
                    setValue(field.name, option.value);
                  }}>
                  <Check className={cn('mr-2 h-4 w-4', option.value === field.value ? 'opacity-100' : 'opacity-0')} />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function InputSelectSearchAsync({ field, loadOptions, placeholder, disabled }: TInputSelectSearchAsyncProps) {
  return (
    <AsyncSelect
      cacheOptions
      isDisabled={disabled}
      defaultOptions
      noOptionsMessage={() => 'Digite o que deseja pesquisar'}
      placeholder={placeholder || 'Digite o que deseja pesquisar'}
      loadOptions={loadOptions}
      onChange={(e: TOptions) => {
        console.log(e);
        if (e?.value) field.onChange(e?.value);
        else if (e?.label) field.onChange(e?.label);
      }}
      isClearable
      formatOptionLabel={(data, ctx) => {
        return (
          <>
            <Highlighter
              highlightClassName="text-neutral-darkest bg-transparent p-0 font-semibold"
              searchWords={[ctx?.inputValue]}
              autoEscape={false}
              // @ts-ignore
              textToHighlight={data?.label}
            />
          </>
        );
      }}
    />
  );
}

function InputDatePicker({ field, disabled, placeholder, disabledDate = null }: TInputDatePickerProps) {
  const { error } = useFormField();
  const inputId = useId();
  const [month, setMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (field.value) {
      const date = new Date(field.value);
      if (isValid(date) && !selectedDate) {
        console.log('Setando Date Picker', date);
        setSelectedDate(date);
        setMonth(date);
        setInputValue(format(date, 'dd/MM/yyyy'));
      }
    }
  }, [field.value]);

  const handleDayPickerSelect = (date: Date | undefined) => {
    if (!date) {
      setInputValue('');
      setSelectedDate(undefined);
    } else {
      setSelectedDate(date);
      setMonth(date);
      setInputValue(format(date, 'dd/MM/yyyy'));
      // field.onChange(format(date, 'dd/MM/yyyy'));
      field.onChange(date);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    const value = e.target.value.replace(/\s+/g, '');

    const parsedDate = parse(value, 'dd/MM/yyyy', new Date());

    if (isValid(parsedDate) && value.length === 10) {
      setSelectedDate(parsedDate);
      setMonth(parsedDate);
      field.onChange(parsedDate);
    } else {
      setSelectedDate(undefined);
      field.onChange('');
    }
  };

  return (
    <Popover>
      <div className="relative">
        <PatternFormat
          disabled={disabled}
          className={cn(error && 'border-danger border-2')}
          id={inputId}
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          format={'##/##/####'}
          customInput={InputBase}
        />
        <PopoverTrigger asChild className={cn('absolute right-3 top-[12px]', disabled && 'pointer-events-none opacity-70')} disabled={disabled}>
          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          disabled={disabledDate}
          month={month}
          onMonthChange={setMonth}
          mode="single"
          startMonth={new Date(new Date().getFullYear() - 100, 1)}
          endMonth={new Date(new Date().getFullYear() + 10, 12)}
          selected={selectedDate}
          onSelect={handleDayPickerSelect}
          captionLayout="dropdown"
          hideNavigation
        />
      </PopoverContent>
    </Popover>
  );
}

function InputDateRange({ field, numberOfMonths = 2 }: TInputDateRangeProps) {
  const { error } = useFormField();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button
          className={cn(
            'border-input bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-primary flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-50',
            !field.value && 'text-muted-foreground',
            error && 'border-danger border-2',
          )}>
          {field.value?.from ? (
            field.value.to ? (
              <>
                {format(field.value.from, 'PPP', { locale: ptBR })} - {format(field.value.to, 'PPP', { locale: ptBR })}
              </>
            ) : (
              format(field.value.from, 'PPP', { locale: ptBR })
            )
          ) : (
            <span>Pick a date</span>
          )}
          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          autoFocus
          mode="range"
          locale={ptBR}
          defaultMonth={field.value?.from || new Date()}
          disabled={(date) => date > new Date() || date < new Date('1900-01-01')}
          selected={field.value}
          onSelect={field.onChange}
          numberOfMonths={2}
        />
      </PopoverContent>
    </Popover>
  );
}

function InputMultipleText({ field, disabled, placeholder, inputMultipleTextRules }: TInputMultipleTextProps) {
  const { error } = useFormField();
  const { getValues, setValue, watch } = useFormContext();
  const ghostInputRef = useRef<HTMLInputElement>(null);
  const fieldWatch = watch(field.name, '');

  function addItem() {
    if (!ghostInputRef.current?.value)
      toast.error('Erro ao adicionar valor', {
        description: 'O campo não pode estar vazio. Insira um valor válido.',
      });

    console.log({ inputMultipleTextRules });
    if (!new RegExp(inputMultipleTextRules || '').test(ghostInputRef.current!.value))
      return toast.error('Erro ao adicionar valor', {
        description: 'Insira um valor válido.',
      });
    const newValue = field.value ? `${field.value},${ghostInputRef.current?.value}` : ghostInputRef.current?.value;

    setValue(field.name, newValue);
    ghostInputRef.current!.value = '';
  }

  return (
    <>
      <div className="relative">
        <InputBase {...field} disabled={disabled} className={'hidden'} placeholder={placeholder} type={'text'} />
        <InputBase ref={ghostInputRef} disabled={disabled} className={cn(error && 'border-invalid')} placeholder={placeholder} type={'text'} />
        <IconButton variant={'ghost'} type="button" disabled={disabled} onClick={addItem} className="absolute right-1 top-[3px]">
          <Plus size={18} />
        </IconButton>
      </div>
      <div className="flex flex-wrap">
        {fieldWatch &&
          fieldWatch.split(',').map((item: string, index: number) => (
            <Badge key={item + '-' + index} className="mr-2 mt-1 flex gap-2 pl-4 pr-2">
              <span>{item}</span>
              <IconButton
                onClick={() => {
                  const newValue = fieldWatch.split(',').filter((_: any, i: any) => i !== index);
                  field.onChange(newValue.join(','));
                }}
                variant={'ghost'}>
                <X size={12} />
              </IconButton>
            </Badge>
          ))}
      </div>
    </>
  );
}

function InputFile({ field, disabled, placeholder, multipleFiles, id, accepts = 'image/*,video/*,.docx,.xlsx,.csv,.pdf' }: TInputFileProps) {
  const { error } = useFormField();
  const formContext = useFormContext();

  //workaround pra fazer funcionar o input file com react-hook-form e shadcn/ui
  const inputRef = formContext.register(field.name);
  return (
    <InputBase
      {...inputRef}
      id={id}
      multiple={multipleFiles}
      accept={accepts}
      disabled={disabled}
      className={cn('cursor-pointer', error && 'border-invalid border-2')}
      placeholder={placeholder}
      onChange={(event) => {
        if (multipleFiles) field.onChange(event.target?.files ?? undefined);
        else field.onChange(event.target?.files?.[0] ?? undefined);
      }}
      type={'file'}
    />
  );
}

function InputCurrency({ field, disabled, placeholder }: TInputCurrencyProps) {
  const { error } = useFormField();

  return (
    <CurrencyInput
      value={field.value}
      onChangeValue={(_, value) => {
        field.onChange(value);
      }}
      InputElement={<InputBase className={cn(error && 'border-danger border-2')} disabled={disabled} placeholder={placeholder} />}
    />
  );
}

function InputPercentage({ field, disabled, placeholder, maxValue, minValue }: TInputPercentageProps) {
  const { error } = useFormField();

  return (
    <NumericFormat
      disabled={disabled}
      className={cn(error && 'border-danger border-2')}
      value={field.value}
      onChange={(e) => field.onChange(e.target.value)}
      placeholder={placeholder}
      suffix="%"
      decimalSeparator="."
      decimalScale={2}
      max={maxValue}
      min={minValue}
      customInput={InputBase}
    />
  );
}

function InputHanlder({
  type,
  field,
  placeholder,
  mask,
  disabled,
  options,
  loadOptions,
  multipleFiles,
  inputMultipleTextRules,
  accepts,
  id,
  numberOfMonths,
  loading,
  radioItems,
  checkboxItems,
  disabledDate,
  maxLength,
  minLength,
  minValue,
  maxValue,
  defaultValue,
  defaultChecked,
  decimalScale = 2,
}: TInputHandlerProps): ReactNode {
  const { error, formItemId } = useFormField();
  switch (type) {
    case EInput.MASK:
      return <InputMask field={field} mask={mask} disabled={disabled} placeholder={placeholder} />;

    case EInput.SWITCH:
      return <InputSwitch field={field} disabled={disabled} defaultChecked={defaultChecked} />;

    case EInput.SELECT:
      return <InputSelect field={field} disabled={disabled} options={options} loading={loading} />;

    case EInput.SELECT_SEARCH:
      return <InputSelectSearch field={field} disabled={disabled} options={options} loading={loading} />;

    case EInput.SELECT_SEARCH_ASYNC:
      return <InputSelectSearchAsync field={field} disabled={disabled} loadOptions={loadOptions} loading={loading} />;

    case EInput.TEXTAREA:
      return (
        <Textarea {...field} disabled={disabled} className={cn(error && 'border-danger border-2')} placeholder={placeholder || 'Digite aqui...'} />
      );

    case EInput.DATEPICKER:
      return <InputDatePicker field={field} placeholder={placeholder || 'Selecione ou digite'} disabledDate={disabledDate} disabled={disabled} />;

    case EInput.DATERANGE:
      return <InputDateRange field={field} numberOfMonths={numberOfMonths} placeholder={placeholder || 'Selecione ou digite'} />;

    case EInput.MULTIPLE_TEXT:
      return <InputMultipleText field={field} disabled={disabled} placeholder={placeholder} inputMultipleTextRules={inputMultipleTextRules} />;

    case EInput.FILE:
      return <InputFile field={field} name={field.name} multipleFiles={multipleFiles} id={id} accepts={accepts} />;

    case EInput.OTP:
      return (
        <InputOTP maxLength={6} disabled={disabled} {...field}>
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />
            <InputOTPSlot index={3} />
            <InputOTPSlot index={4} />
            <InputOTPSlot index={5} />
          </InputOTPGroup>
        </InputOTP>
      );

    case EInput.CHECKBOX:
      return <InputCheckbox field={field} disabled={disabled} defaultChecked={defaultChecked} />;

    case EInput.CHECKBOX_GROUP:
      return <InputCheckboxGroup field={field} disabled={disabled} checkboxItems={checkboxItems} />;

    case EInput.RADIO_GROUP:
      return <InputRadioGroup field={field} disabled={disabled} radioItems={radioItems} />;

    case EInput.CURRENCY:
      return <InputCurrency field={field} disabled={disabled} placeholder={placeholder} minValue={minValue} maxValue={maxValue} />;

    case EInput.PERCENTAGE:
      return (
        <InputPercentage
          field={field}
          disabled={disabled}
          placeholder={placeholder}
          minValue={minValue}
          maxValue={maxValue}
          decimalScale={decimalScale}
        />
      );

    default:
      return (
        <InputBase
          {...field}
          disabled={disabled}
          className={cn(error && 'border-danger border-2')}
          placeholder={placeholder}
          type={type}
          maxLength={maxLength}
          minLength={minLength}
        />
      );
  }
}

export function Input(props: TInputProps) {
  const { label, name, description, loading } = props;
  const rhfInstance = useFormContext();

  return (
    <FormField
      control={rhfInstance.control}
      name={name}
      defaultValue={props.defaultValue ? props.defaultValue : props.type === EInput.SWITCH || props.type === EInput.CHECKBOX ? false : ''}
      render={({ field }) => {
        return (
          <FormItem className="relative mb-4 w-full">
            <div className={cn('w-full space-y-1', props.className)}>
              {<FormLabel htmlFor={props.id}>{label}</FormLabel>}
              <FormControl>
                {loading ? (
                  <Skeleton className="border-input ring-offset-background placeholder:text-muted-foreground focus-visible:!border-primary flex h-10 w-full rounded-md border bg-gray-400 px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:!border-2 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50" />
                ) : (
                  <InputHanlder field={field} {...props} />
                )}
              </FormControl>
              {description && !loading && <FormDescription className="text-xs">{description}</FormDescription>}
            </div>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

export function Form({ children, ctx, className, onSubmit }: TFormProps) {
  return (
    <FormBase {...ctx}>
      <form onSubmit={ctx.handleSubmit(onSubmit)} className={className}>
        {children}
      </form>
    </FormBase>
  );
}
