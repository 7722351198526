import bvUtils from '@bv/utils';
import { UserCircle } from 'lucide-react';

type UserRecordProps = {
  name: string;
  cpf: string;
  email?: string;
  job?: string;
};

export function UserRecord({ name, cpf: document, email, job }: UserRecordProps) {
  return (
    <div className="rounded-2 group flex gap-2 bg-white p-2 shadow-sm">
      <span className="relative mt-[2px]">
        <UserCircle size={16} className="text-brand-primary-dark" />
      </span>
      <div className="flex flex-col gap-1">
        <strong className="text-brand-primary-dark text-sm">{name}</strong>
        <span className="text-xs text-gray-500">{document.length === 11 ? bvUtils.mask.applyCpfMask(document) : document}</span>
        {email && <span className="hidden text-xs text-gray-500 group-hover:block">{email}</span>}
        {job && <span className="hidden text-xs text-gray-500 group-hover:block">{job}</span>}
      </div>
    </div>
  );
}
