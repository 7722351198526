import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import React from 'react';

type THeaderProps = React.AllHTMLAttributes<HTMLDivElement> & {
  title: string;
  description?: string;
  children?: React.ReactNode;
};

export default function Header({ title, children, description, className, ...props }: THeaderProps) {
  return (
    <div className={cn('py-4', className)} {...props}>
      <div className="flex-1 space-y-1 sm:space-y-4">
        <motion.h3
          className={cn('text-typography-body font-sans text-xl font-semibold sm:text-2xl', !!description && 'mb-0')}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}>
          {title}
        </motion.h3>
        {description && (
          <motion.p
            className="text-typography-secondary mb-0 font-sans text-sm sm:text-base sm:leading-[150%]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}>
            {description}
          </motion.p>
        )}
      </div>
      {children}
    </div>
  );
}
