import { Alert, AlertDescription, Button } from '@eyecarehealth/ds-aquilae-react';
import { AlertCircleIcon } from 'lucide-react';
import { motion } from 'framer-motion';
import {
  equipmentGlassesAsset,
  equipmentIPEAsset,
  equipmentLensesAsset,
  faceNotDeficientAsset,
  leftEyeDeficientAsset,
  rightEyeDeficientAsset,
} from '../../assets';
import { AnimatedSelector } from '../AnimatedSelector';

const EQUIPS_OPTIONS = [
  {
    label: 'Óculos de grau',
    description: '',
    value: 'glasses',
    image: equipmentGlassesAsset,
  },
  {
    label: 'Lente de contato',
    description: '',
    value: 'contact',
    image: equipmentLensesAsset,
  },
  {
    label: 'Óculos EPI com grau',
    description: '(Equipamento de Proteção Individual)',
    value: 'epi',
    image: equipmentIPEAsset,
  },
  {
    label: 'Não estou usando nada',
    description: '',
    value: 'none',
    image: faceNotDeficientAsset,
  },
];

type IsWearingGlassesProps = {
  onSelectorChange: React.Dispatch<React.SetStateAction<string>>;
  optionSelected: string;
  onConfirm: () => void;
};

export default function IsWearingGlasses({ onSelectorChange, optionSelected, onConfirm }: IsWearingGlassesProps) {
  return (
    <motion.div
      className="flex h-full w-full max-w-[420px] flex-col justify-start gap-4 md:justify-center"
      initial={{ opacity: 0, scale: 1.05 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.4 }}>
      <div className="flex flex-1 flex-col justify-start gap-4 max-sm:max-h-[calc(100dvh-100px)] max-sm:overflow-y-auto md:justify-center">
        <h1 className="text-typography text-center font-bold md:text-left">Antes de começar</h1>
        <p className="text-typography-secondary font-ubuntu text-center text-sm md:text-left">
          Para garantir um resultado eficiente, informe se está usando um dos itens abaixo:
        </p>
        <AnimatedSelector
          imageClassName="size-[88px] max-[1400px]:size-[64px]"
          options={EQUIPS_OPTIONS}
          optionSelected={optionSelected}
          onSelectorChange={onSelectorChange}
        />
        <Alert variant="info">
          <AlertCircleIcon size={24} className="text-brand-primary" />
          <AlertDescription className="text-left">
            Essas informações são importantes para adaptar os testes visuais às suas necessidades.
          </AlertDescription>
        </Alert>
      </div>
      <Button size="lg" onClick={onConfirm} disabled={!optionSelected}>
        Confirmar
      </Button>
    </motion.div>
  );
}
