import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import React from 'react';

type THeaderProps = React.AllHTMLAttributes<HTMLDivElement> & {
  title: string;
  description?: string;
  children?: React.ReactNode;
};

export default function Header({ title, children, description, className, ...props }: THeaderProps) {
  return (
    <div className={cn('pb-4 pt-4 sm:pb-6', className)} {...props}>
      <div className="flex-1 space-y-1 sm:space-y-2">
        <h3 className={cn('text-typography font-sans text-xl font-semibold sm:text-2xl', !!description && 'mb-0')}>{title}</h3>
        {description && <p className="text-typography-secondary mb-0 font-sans text-sm sm:text-base sm:leading-[150%]">{description}</p>}
      </div>
      {children}
    </div>
  );
}
