import React, { useState } from 'react';
import * as singleSpa from 'single-spa';
import { Button, Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@eyecarehealth/ds-aquilae-react';

type TResponsibilityWarnModalProps = {
  action?: () => void;
};

function ResponsibilityWarnModal({ action }: TResponsibilityWarnModalProps) {
  const [show, setShow] = useState(true);

  const closeWarnModal = () => {
    setShow(false);
    if (action) action();
  };

  return (
    <Dialog open={show}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle className="text-left">Antes de prosseguir</DialogTitle>
          <DialogDescription className="text-left">Você deve concordar com os seguintes termos:</DialogDescription>
        </DialogHeader>
        <ul className="list-disc pl-4">
          <li className="text-muted-foreground mb-2 text-sm">
            Os testes fornecidos pelo Benefício Visão são de uso exclusivo de seus usuários. Os dados de login e acesso não devem ser compartilhados
            com terceiros.
          </li>
          <li className="text-muted-foreground text-sm">
            Ao realizar os testes, você assume responsabilidade pela veracidade das informações de saúde.
          </li>
        </ul>
        <DialogFooter>
          <Button onClick={() => singleSpa.navigateToUrl('/dashboard')} variant="ghost">
            Não concordo
          </Button>
          <Button onClick={closeWarnModal}>Estou de acordo</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
export default React.memo(ResponsibilityWarnModal);
