import BVUtils from '@bv/utils';
import BVUtilsOld from '@bv/utils-old';
import { motion } from 'framer-motion';

type THeaderProps = {
  title: string;
};

function getInitials(name: string): string {
  const parts = name.trim().split(/\s+/);

  const initials = parts
    .slice(0, 2)
    .map((part) => part[0].toUpperCase())
    .join('');

  return initials;
}

export default function Header({ title }: THeaderProps) {
  const decodedToken = BVUtils.token.getDecodedToken();

  function logout() {
    window.localStorage.clear();
    BVUtilsOld.cookiesHelper.clearAuthCookies();
    window.location.href = '/login';
  }

  function clearUserViewType() {
    window.localStorage.removeItem('userViewType');
    window.location.reload();
  }

  return (
    <div className="space-y-4 pb-4">
      <motion.h3 className="text-typography-body font-sans text-2xl font-semibold" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {title}
      </motion.h3>
      <motion.div className="bg-primary h-[2px]" initial={{ width: 0 }} animate={{ width: 80 }} />
    </div>
  );
}
