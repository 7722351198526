import { Button, IconButton } from './components/Button';
import { ErrorPleaseReload } from './components/Error';
import Header from './components/Header';
import { EInput, Form, Input } from './components/Input';
import { Input as InputPure } from './components/ui/input';
import { Loader, PageLoader, PageLoaderEyecare } from './components/Loader';
import BVPopover from './components/Popover';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
import { Avatar, AvatarFallback } from './components/ui/avatar';
import { Badge } from './components/ui/badge';
import { Checkbox } from './components/ui/checkbox';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from './components/ui/drawer';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './components/ui/dropdown-menu';
import { Label } from './components/ui/label';
import { Progress } from './components/ui/progress';
import { ScrollArea } from './components/ui/scroll-area';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from './components/ui/select';
import { Separator } from './components/ui/separator';
import { Skeleton } from './components/ui/skeleton';
import { Switch } from './components/ui/switch';
import { ToggleGroup, ToggleGroupItem } from './components/ui/toggle-group';
import './global.css';
import { cn } from './lib/utils';
import {
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
} from './components/ui/sheet';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Pagination, usePagination } from './components/Pagination';
import { DataTable } from './components/DataTable';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { UserRecord } from './components/UserRecord';
import Layout from './components/Layout';

type TStyleguide = {
  Accordion: typeof Accordion;
  AccordionContent: typeof AccordionContent;
  AccordionItem: typeof AccordionItem;
  AccordionTrigger: typeof AccordionTrigger;
  Avatar: typeof Avatar;
  AvatarFallback: typeof AvatarFallback;
  Checkbox: typeof Checkbox;
  Command: typeof Command;
  CommandEmpty: typeof CommandEmpty;
  CommandGroup: typeof CommandGroup;
  CommandInput: typeof CommandInput;
  CommandItem: typeof CommandItem;
  CommandList: typeof CommandList;
  DataTable: typeof DataTable;
  Dialog: typeof Dialog;
  DialogContent: typeof DialogContent;
  DialogDescription: typeof DialogDescription;
  DialogFooter: typeof DialogFooter;
  DialogHeader: typeof DialogHeader;
  DialogTitle: typeof DialogTitle;
  DialogTrigger: typeof DialogTrigger;
  DialogClose: typeof DialogClose;
  Drawer: typeof Drawer;
  DrawerClose: typeof DrawerClose;
  DrawerContent: typeof DrawerContent;
  DrawerDescription: typeof DrawerDescription;
  DrawerFooter: typeof DrawerFooter;
  DrawerHeader: typeof DrawerHeader;
  DrawerTitle: typeof DrawerTitle;
  DrawerTrigger: typeof DrawerTrigger;
  DropdownMenu: typeof DropdownMenu;
  DropdownMenuContent: typeof DropdownMenuContent;
  DropdownMenuItem: typeof DropdownMenuItem;
  DropdownMenuLabel: typeof DropdownMenuLabel;
  DropdownMenuSeparator: typeof DropdownMenuSeparator;
  DropdownMenuTrigger: typeof DropdownMenuTrigger;
  ErrorPleaseReload: typeof ErrorPleaseReload;
  Form: typeof Form;
  HoverCard: typeof HoverCard;
  HoverCardContent: typeof HoverCardContent;
  HoverCardTrigger: typeof HoverCardTrigger;
  Input: typeof Input;
  InputPure: typeof InputPure;
  IconButton: typeof IconButton;
  Button: typeof Button;
  Badge: typeof Badge;
  Loader: typeof Loader;
  Layout: typeof Layout;
  Label: typeof Label;
  PageLoader: typeof PageLoader;
  PageLoaderEyecare: typeof PageLoaderEyecare;
  Alert: typeof Alert;
  AlertTitle: typeof AlertTitle;
  AlertDescription: typeof AlertDescription;
  Pagination: typeof Pagination;
  Progress: typeof Progress;
  Header: typeof Header;
  ScrollArea: typeof ScrollArea;
  Select: typeof Select;
  SelectContent: typeof SelectContent;
  SelectGroup: typeof SelectGroup;
  SelectItem: typeof SelectItem;
  SelectLabel: typeof SelectLabel;
  SelectTrigger: typeof SelectTrigger;
  SelectValue: typeof SelectValue;
  Separator: typeof Separator;
  Sheet: typeof Sheet;
  SheetPortal: typeof SheetPortal;
  SheetOverlay: typeof SheetOverlay;
  SheetTrigger: typeof SheetTrigger;
  SheetClose: typeof SheetClose;
  SheetContent: typeof SheetContent;
  SheetHeader: typeof SheetHeader;
  SheetFooter: typeof SheetFooter;
  SheetTitle: typeof SheetTitle;
  SheetDescription: typeof SheetDescription;
  Skeleton: typeof Skeleton;
  Switch: typeof Switch;
  BVPopover: typeof BVPopover;
  Popover: typeof Popover;
  PopoverContent: typeof PopoverContent;
  PopoverTrigger: typeof PopoverTrigger;
  Table: typeof Table;
  TableBody: typeof TableBody;
  TableCaption: typeof TableCaption;
  TableCell: typeof TableCell;
  TableHead: typeof TableHead;
  TableHeader: typeof TableHeader;
  TableRow: typeof TableRow;
  Tabs: typeof Tabs;
  TabsContent: typeof TabsContent;
  TabsList: typeof TabsList;
  TabsTrigger: typeof TabsTrigger;
  ToggleGroup: typeof ToggleGroup;
  ToggleGroupItem: typeof ToggleGroupItem;
  Tooltip: typeof Tooltip;
  TooltipContent: typeof Tooltip;
  TooltipProvider: typeof Tooltip;
  TooltipTrigger: typeof Tooltip;
  UserRecord: typeof UserRecord;

  //ENUMS
  EInput: typeof EInput;

  //FUNCTIONS
  cn: typeof cn;

  //HOOKS
  usePagination: typeof usePagination;
};

const Styleguide: TStyleguide = {
  //COMPONENTS
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Avatar,
  AvatarFallback,
  IconButton,
  Checkbox,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  DataTable,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  ErrorPleaseReload,
  Form,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Input,
  InputPure,
  Button,
  Badge,
  Loader,
  Layout,
  Label,
  PageLoader,
  PageLoaderEyecare,
  Pagination,
  Alert,
  AlertTitle,
  AlertDescription,
  Progress,
  Header,
  ScrollArea,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  Separator,
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
  Skeleton,
  Switch,
  BVPopover,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  ToggleGroup,
  ToggleGroupItem,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  UserRecord,

  //ENUMS
  EInput,

  //FUNCTIONS
  cn,

  //HOOKS
  usePagination,
};

export default Styleguide;
