import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';

import { cn } from '@/lib/utils';

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & { indicatorClassName?: string }
>(({ className, indicatorClassName, value, ...props }, ref) => (
  <ProgressPrimitive.Root ref={ref} className={cn('relative h-4 w-full overflow-hidden rounded-full bg-gray-400', className)} {...props}>
    <ProgressPrimitive.Indicator
      className={cn('h-full flex-1 transition-all', indicatorClassName)} // Aplicamos a prop indicatorClassName aqui
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }} // Calcula a barra de progresso
    />
  </ProgressPrimitive.Root>
));

Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
