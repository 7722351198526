import { VariantProps } from 'class-variance-authority';
import { buttonVariants } from './ui/button';
import { Button as UIButton } from './ui/button';
import { MouseEventHandler } from 'react';
import { Loader } from './Loader';
import { cn } from '@/lib/utils';

export type TButtonProps = VariantProps<typeof buttonVariants> & {
  loading?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  role?: string;
};

export type TIconButtonProps = TButtonProps & {
  className?: string;
};

export function Button({ loading, disabled, onClick, children, type = 'button', variant = 'default', className, size, role }: TButtonProps) {
  return (
    <UIButton
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
      variant={variant}
      size={size}
      role={role}
      className={cn('flex items-center justify-center', loading && 'cursor-not-allowed', className)}>
      {loading && (
        <div className="flex items-center justify-center">
          <Loader className="text-white" text="Aguarde..." />
        </div>
      )}
      {!loading && children}
    </UIButton>
  );
}

export function IconButton({ loading, disabled, onClick, children, type = 'button', variant = 'ghost', className }: TIconButtonProps) {
  return (
    <UIButton
      variant={variant}
      className={cn('flex h-auto w-auto items-center justify-center rounded-full p-2', className, (disabled || loading) && 'cursor-not-allowed')}
      onClick={onClick}
      type={type}
      disabled={loading || disabled}>
      {loading ? <Loader className="h-5 w-5 text-white" /> : children}
    </UIButton>
  );
}
