import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import React from 'react';

type LayoutProps = React.AllHTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

const routeAnimationVariants = {
  initial: {
    opacity: 0,
    y: -8,
  },
  final: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 8,
  },
};

export default function Layout({ children, className }: LayoutProps) {
  return (
    <motion.div
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 40,
      }}
      key="identifier"
      exit="exit"
      animate="final"
      initial="initial"
      variants={routeAnimationVariants}
      className="h-full overflow-hidden">
      <div className={cn('flex h-full flex-col max-[768px]:pt-16', 'transition-all duration-300 ease-in-out', className)}>{children}</div>
    </motion.div>
  );
}
