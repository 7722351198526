import { AddressAutocomplete } from './components/AddressAutocomplete';
import { AnimatedSelector } from './components/AnimatedSelector';
import { BorderBeam } from './components/BorderBeam';
import { Button } from './components/Button';
import { ErrorPleaseReload } from './components/Error';
import { Feedback } from './components/Feedback';
import Header from './components/Header';
import HealthDataCollect from './components/HealthDataCollect';
import { ImagePreview } from './components/ImagePreview';
import IsWearingGlasses from './components/IsWearingGlasses';
import Layout from './components/Layout';
import { Loader, PageLoader, PageLoaderEyecare } from './components/Loader';
import { ProtectedByPlan } from './components/ProtectedByPlan';
import ResponsibilityWarnModal from './components/ResponsibilityWarnModal';
import { Ripple } from './components/Ripple';
import { Icons } from './components/SvgIcons';
import { TanstackTable } from './components/TanstackTable';
import { UserRecord } from './components/UserRecord';
import './global.css';
import { useEmblaCarouselDots } from './hooks/useEmblaCarouselDots';

//trigger pipeline4

type TStyleguide = {
  Icons: typeof Icons;
  Feedback: typeof Feedback;
  AddressAutocomplete: typeof AddressAutocomplete;
  ImagePreview: typeof ImagePreview;
  TanstackTable: typeof TanstackTable;
  ErrorPleaseReload: typeof ErrorPleaseReload;
  Button: typeof Button;
  Loader: typeof Loader;
  Layout: typeof Layout;
  PageLoader: typeof PageLoader;
  PageLoaderEyecare: typeof PageLoaderEyecare;
  ProtectedByPlan: typeof ProtectedByPlan;
  Header: typeof Header;
  UserRecord: typeof UserRecord;
  ResponsibilityWarnModal: typeof ResponsibilityWarnModal;
  AnimatedSelector: typeof AnimatedSelector;
  HealthDataCollect: typeof HealthDataCollect;
  IsWearingGlasses: typeof IsWearingGlasses;
  Ripple: typeof Ripple;
  BorderBeam: typeof BorderBeam;

  //ENUMS

  //FUNCTIONS

  //HOOKS
  useEmblaCarouselDots: typeof useEmblaCarouselDots;
};

const Styleguide: TStyleguide = {
  //COMPONENTS
  Icons,
  Feedback,
  AddressAutocomplete,
  ImagePreview,
  TanstackTable,
  ErrorPleaseReload,
  Button,
  Loader,
  Layout,
  PageLoader,
  PageLoaderEyecare,
  Header,
  ProtectedByPlan,
  UserRecord,
  ResponsibilityWarnModal,
  AnimatedSelector,
  HealthDataCollect,
  IsWearingGlasses,
  Ripple,
  BorderBeam,
  //ENUMS

  //FUNCTIONS

  //HOOKS
  useEmblaCarouselDots,
};

export default Styleguide;
