import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';

import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { ptBR } from 'date-fns/locale';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './select';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      locale={ptBR}
      showOutsideDays={showOutsideDays}
      className={cn('flex p-3', className)}
      classNames={{
        month_caption: 'flex justify-center items-center',
        dropdown_root: 'text-sm first:flex-1',
        dropdowns: 'flex w-full space-x-2 justify-between',
        weeks: 'flex flex-col space-y-4 sm:space-x-0 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'hidden',
        nav: 'space-x-1 flex items-center',
        button_previous: cn(buttonVariants({ variant: 'outline' }), 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute left-1 top-5'),
        button_next: cn(buttonVariants({ variant: 'outline' }), 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute right-1 top-5'),
        month_grid: 'w-full border-collapse space-y-1',
        weekdays: 'flex',
        dropdown: 'text-center border-2 border-gray-200 rounded-2 p-2 text-gray-800 w-full text-capitalize',
        weekday: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        day: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day_button: cn(buttonVariants({ variant: 'ghost' }), 'h-9 w-9 p-0 font-normal aria-selected:opacity-100'),
        range_end: 'day-range-end',
        selected: 'bg-primary text-white hover:bg-primary hover:text-white focus:bg-primary focus:text-primary-foreground rounded-3',
        today: 'bg-accent text-accent-foreground',
        outside:
          'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        disabled: 'text-muted-foreground opacity-50',
        range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        hidden: 'invisible',
        ...classNames,
      }}
      // components={
      //   {
      // Chevron: ({ ...props }) => {
      //   if (props.orientation === 'left') return <ChevronLeft className="h-4 w-4" {...props} />;
      //   return <ChevronRight className="h-4 w-4" {...props} />;
      // },
      // Select: ({ ...props }) => {
      //   console.log(props);
      //   return (
      //     <Select>
      //       <SelectTrigger className="w-[180px]">
      //         <SelectValue onChange={props.onChange} placeholder="Select a fruit" />
      //       </SelectTrigger>
      //       <SelectContent>
      //         {(props.children as { props: { value: any; disabled: boolean; children: string } }[]).map((item) => (
      //           <SelectItem value={item.props.value}>{item.props.children}</SelectItem>
      //         ))}
      //       </SelectContent>
      //     </Select>
      //   );
      // },
      //   }
      // }
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
