import { Alert, AlertDescription, Button } from '@eyecarehealth/ds-aquilae-react';
import { AlertCircleIcon } from 'lucide-react';
import { motion } from 'framer-motion';
import { faceNotDeficientAsset, leftEyeDeficientAsset, rightEyeDeficientAsset } from '../../assets';
import { AnimatedSelector } from '../AnimatedSelector';

const HEALTH_DATA_OPTIONS = [
  {
    label: 'Não',
    value: 'none',
    image: faceNotDeficientAsset,
  },
  {
    label: 'Sim, do olho esquerdo',
    value: 'leftEye',
    image: leftEyeDeficientAsset,
  },
  {
    label: 'Sim, do olho direito',
    value: 'rightEye',
    image: rightEyeDeficientAsset,
  },
];

type HealthDataCollectProps = {
  onSelectorChange: React.Dispatch<React.SetStateAction<string>>;
  optionSelected: string;
  onConfirm: () => void;
  isLoading: boolean;
};

export default function HealthDataCollect({ onSelectorChange, optionSelected, onConfirm, isLoading }: HealthDataCollectProps) {
  return (
    <motion.div
      className="flex w-[420px] max-w-full flex-col gap-4"
      initial={{ opacity: 0, scale: 1.05 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.4 }}>
      <h1 className="text-typography text-center font-bold md:text-left">
        Queremos saber mais sobre sua visão para personalizar ainda mais os testes.
      </h1>
      <p className="text-typography-secondary font-ubuntu text-center text-sm md:text-left">Você é cego de um dos olhos ou usa prótese ocular?</p>
      <AnimatedSelector options={HEALTH_DATA_OPTIONS} optionSelected={optionSelected} onSelectorChange={onSelectorChange} />
      <Alert variant="info">
        <AlertCircleIcon size={24} className="text-brand-primary" />
        <AlertDescription className="text-left">
          Essas informações são importantes para adaptar os testes visuais às suas necessidades.
        </AlertDescription>
      </Alert>
      <Button size="lg" onClick={onConfirm} disabled={!optionSelected} loading={isLoading}>
        Confirmar
      </Button>
    </motion.div>
  );
}
