import { cn } from '@/lib/utils';
import React from 'react';

type LayoutProps = React.AllHTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

export default function Layout({ children, className }: LayoutProps) {
  return (
    <div className="h-screen overflow-hidden">
      <div className={cn('flex h-screen flex-col max-[768px]:mt-16 max-[768px]:px-2 md:px-8', 'transition-all duration-300 ease-in-out', className)}>
        {children}
      </div>
    </div>
  );
}
