import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const badgeVariants = cva(
  'transition-all inline-flex items-center rounded-full font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'bg-primary-light text-primary hover:bg-primary-light/80',
        secondary: 'bg-secondary-light/60 text-secondary-dark hover:bg-secondary-light/40',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/80',
        success: 'bg-success/40 text-success-dark hover:bg-success/30',
        warning: 'bg-warning/40 text-warning-dark hover:bg-warning/30',
        info: 'bg-info/50 text-info-dark hover:bg-info/30',
        outline: 'text-foreground border border-foreground hover:bg-foreground/10',
      },
      size: {
        default: 'px-2.5 py-2 text-xs',
        sm: 'px-2 py-1.5 text-[10px]',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size = 'default', ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant, size }), className)} {...props} />;
}

export { Badge, badgeVariants };
